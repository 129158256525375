import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { observer, inject  } from "mobx-react";
import GrisModal from './gris_modal';
import Input from '../components/forms/input';
import { PayPalButton } from "react-paypal-button-v2";

import ApiWrapper from '../utils/api-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCheck, faDollarSign, faMapMarkerAlt, faMinus, faPlus, faUserMinus } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(observer(withRouter(class Events extends Component  {
    constructor(props) {
        super(props);

        this.state = {
            events : [],
            selectedId : null,
            showRegisterModal : false,
            showAddGuestModal : false,
            removeGuestConfirmModal : null,
            showPleaseWaitModal : false,
            showPayModal : false,
            newGuestName: "",
            priceId : "",
            prices : [],
            errorMessage : "",
            successMessage : "",
        }
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        let data = {};

        if (this.props.limit > 0) {
            data["limit"] = this.props.limit;
        }

        ApiWrapper.fetch(this, "/events", "GET", data,
        response => {
			this.setState({
				events : response.data.events,
                cancelRegisterConfirmModal : null,
                showRegisterModal: false,
                showPleaseWaitModal : false
			})
        })
    }

    setExpanded(id) {
        if (this.state.selectedId == id) {
            this.setState({
                selectedId : null
            });
        }
        else {
            this.setState({
                selectedId : id,
                errorMessage : "",
                successMessage : "",
            });
        }
    }

    doToggleRegister() {
        ApiWrapper.fetch(this, "/update-event-member-state", "POST", {
            id : this.state.selectedId,
            action_type : "toggle_register",
            id_price : this.state.priceId,
        },
        response => {
           this.refreshData();
        },
        errorResponse => {
            this.setState({
                showPleaseWaitModal : false,
                errorMessage : "Une erreur interne est survenue",
            }, () => {
                this.refreshData();
            })
        });
    }

    register(event, eventPrices = null) {
        this.setState({
            showRegisterModal : true,
            prices : eventPrices,
            priceId : event.prices != null && event.prices.length > 0 ? event.prices[0].id : "",
        })
    }

    unregister(event) {
        this.setState({
            showPleaseWaitModal : true
        }, () => {
            this.doToggleRegister();
        })
    }

    addGuest(event) {
        this.setState({
            showAddGuestModal : true,
            newGuestName : "",
            prices : event.prices,
            priceId : event.prices != null && event.prices.length > 0 ? event.prices[0].id : "",
        })
    }

    doAddGuest() {
        if (this.state.newGuestName.trim().length > 0) {
            ApiWrapper.fetch(this, "/update-event-member-state", "POST", {
                id : this.state.selectedId,
                action_type : "add_guest",
                name : this.state.newGuestName,
                id_price : this.state.priceId,
            },
            response => {
                this.setState({
                    newGuestName : "",
                    showAddGuestModal : false
                },
                () => {
                    this.refreshData();
                })
            });
        }
    }

    removeGuest(guestId) {
        this.setState({
            removeGuestConfirmModal:guestId
        })
    }

    doRemoveGuest() {
        ApiWrapper.fetch(this, "/update-event-member-state", "POST", {
            id : this.state.selectedId,
            action_type : "remove_guest",
            id_guest : this.state.removeGuestConfirmModal
        },
        response => {
            this.setState({
                removeGuestConfirmModal : null
            }, () => {
                this.refreshData();
            })
        });
    }

    getPriceInfo(event, priceId) {
        let priceInfo = "";
        let price = "";

        event.prices.forEach(p => {
            if (p.id == priceId) {
                priceInfo = p.name;
                price = p.price;
            }
        })

        return " (" + priceInfo + ", " + price + " $)";
    }

    addToCalendar(event) {
        let d = new Date(event.start_time.substring(0, event.start_time.indexOf(" ")));
        let offset = -d.getTimezoneOffset()/60;

        let evt = {
            title : event.title,
            description : event.description,
            location : event.location,
            start_time : event.start_time,
            end_time : event.end_time != null && event.end_time.length > 0 ? event.end_time : event.start_time,
            timezone_offset : offset
        };

        if (window.addToCalendar != null) {
            window.addToCalendar.postMessage(JSON.stringify(evt));
        }
    }

    showPay(event) {
        this.setState({
            showPayModal : true,
            errorMessage : "",
            successMessage : "",
        })
    }

    findSelectedEvent() {
        let evt = null;

        if (this.state.events != null && this.state.selectedId != null) {
            this.state.events.forEach(event => {
                if (event.id == this.state.selectedId) {
                    evt = event;
                }
            })
        }

        return evt;
    }

    completePaypalTransaction(orderId) {
        let evt = this.findSelectedEvent();

        if (evt != null) {
            this.setState({
                showPayModal : false,
                showPleaseWaitModal : true,
            }, () => {
                ApiWrapper.fetch(this, "/validate-event-payment", "POST", {
                    id : orderId,
                    id_event_user : evt.id_event_user,
                },
                response => {
                    this.setState({
                        showPleaseWaitModal : false,
                        successMessage : response.data.result[0] == "SUCCESS_MSG" ? response.data.result[1] : "",
                        errorMessage : response.data.result[0] == "ERROR_MSG" ? response.data.result[1] : "",
                    }, () => {
                        this.refreshData();
                    })
                },
                errorResponse => {
                    this.setState({
                        showPleaseWaitModal : false,
                        errorMessage : "Une erreur interne est survenue",
                    }, () => {
                        this.refreshData();
                    })
                }
                );
            })
        }
    }

    getPriceHtml() {
        return  <div className="mt-3 padded">
                    Type d'inscription :
                    {
                        this.state.prices.map(price => {

                            return <div className="row bm-spacer mt-1" key={"price-" + price.id }>
                                <div className="col-12">
                                    <label style={{marginBottom:0}}>
                                        <input type="radio" checked={ this.state.priceId == price.id } name="price-item" value={price.id} onChange={() => this.setState({priceId : price.id})} />
                                        {" " + price.name} ({price.price} $)
                                    </label>
                                    {
                                        price.details != null && price.details.length > 0 ?
                                            <div className="ml-3">
                                                <small style={{whiteSpace: "pre-wrap"}}>
                                                    {price.details}
                                                </small>
                                            </div>
                                        :
                                            null
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
    }

    render() {
        let evt = this.findSelectedEvent();

        return  <div className="list">
					<h4 className="padded">Prochains Événements</h4>
                    <div className="error-section">
                        {
                            this.state.errorMessage != "" ?
                                <div className="alert alert-danger" role="alert">
                                    {this.state.errorMessage}
                                </div>
                            :
                            this.state.successMessage != "" ?
                                <div className="alert alert-success" role="alert">
                                    {this.state.successMessage}
                                </div>
                            :
                                null
                        }
                    </div>
                    {
                        this.state.showPleaseWaitModal ?
                            <GrisModal title="Opération en cours">
                                Veuillez patientez...
                            </GrisModal>
                        :
                        this.state.showAddGuestModal ?
                            <GrisModal title="Ajout d'un invité" okText="Ajouter" okAction={() => this.doAddGuest()} cancelAction={() => this.setState({showAddGuestModal : false}) }>
								<div className="row">
                                    <Input label="Nom de l'invité" col="12" value={this.state.newGuestName} name="new_guest_name" valueChanged={(name, value) => this.setState({newGuestName : value}) } />
                                    {
                                        this.state.prices != null && this.state.prices.length > 0 ?
                                            this.getPriceHtml()
                                        :
                                            null
                                    }
                                </div>
                            </GrisModal>
                        :
                        this.state.showRegisterModal ?
                            <GrisModal title="Inscription" okText="Inscription" okAction={() => this.doToggleRegister()} cancelAction={() => this.setState({showRegisterModal : false}) }>
                                Voulez-vous vraiment vous inscrire à cet événement?
								<div className="row">
                                    {
                                        this.state.prices != null && this.state.prices.length > 0 ?
                                            this.getPriceHtml()
                                        :
                                            null
                                    }
                                </div>
                            </GrisModal>
                        :
                        this.state.removeGuestConfirmModal != null ?
                            <GrisModal title="Retirer cet invité?" okText="Retirer" okAction={() => this.doRemoveGuest()} cancelAction={() => this.setState({removeGuestConfirmModal : null}) }>
                                Retirer cet invité de l'événement?
                            </GrisModal>
                        :
                        this.state.cancelRegisterConfirmModal != null ?
                            <GrisModal title="Annulation d'inscrption" okText="Annuler l'inscription" okAction={() => this.unregister()} cancelAction={() => this.setState({cancelRegisterConfirmModal : null}) }>
                                Annuler l'inscription à l'événement :
                                <div>
                                    <strong>{this.state.cancelRegisterConfirmModal.title}</strong>
                                </div>
                            </GrisModal>
                        :
                        this.state.showPayModal && evt != null && evt.id_event_user != null ?
                            <GrisModal title="Paiement" cancelAction={() => this.setState({showPayModal : null}) }>
                                <PayPalButton
                                    createOrder={(data, actions) => {
                                        return actions.order.create({
                                            purchase_units: [
                                                {
                                                    amount: {
                                                        currency_code: 'CAD',
                                                        value: evt.calculatedPrice + "",
                                                    },
                                                    description: evt.title,
                                                },
                                            ],
                                            application_context: {
                                                shipping_preference: 'NO_SHIPPING',
                                            },
                                        });
                                    }}
                                    onSuccess={(details, data) => {
                                        this.completePaypalTransaction(data.orderID)
                                    }}
                                    onError={err => {
                                        this.setState({
                                            errorMessage : "Une erreur est survenue lors du paiement",
                                            showPayModal : false
                                        })
                                    }}
                                />
                            </GrisModal>
                        :
                            null
                    }

                    {
                        this.state.events.length == 0 ?
                            <div className="padded">
                                <i>Aucun événement à venir...</i>
                            </div>
                        :
                        this.state.events.map(event => {
                            return <div className="list-item" key={"event-" + event.id}>
                                    <div className={"item-main-line padded "  + (event.isRegistered ? "registered-status" : "")} onClick={() => this.setExpanded(event.id)}>
                                        <div className="location-info">{ event.title }</div>
                                        <div className="general-info">
                                            <div className="date-info">{ event.start_time }</div>
                                            <div>
                                            {
                                                event.prices == null || event.prices.length == 0 ?
                                                    <span>Gratuit</span>
                                                :
                                                event.calculatedPrice != null && event.calculatedPrice > 0 ?
                                                    <span>
                                                        {event.calculatedPrice} $
                                                        {
                                                            event.hasPaid ?
                                                                " (Payé)"
                                                            :
                                                                " (Non payé)"
                                                        }

                                                    </span>
                                                :
                                                    <span>Payant</span>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"details-info padded " + (this.state.selectedId == event.id ? "expanded-lg" : "")}>
                                        <div className="flex-detail-line">
                                            <div>Participants :</div>
                                            <div>
                                                { event.attendees_count}{ event.max_attendees > 0 ? "/" + event.max_attendees : ""}
                                                { event.is_full ? " (complet)" : ""}
                                            </div>
                                        </div>
                                        <div className="flex-detail-line">
                                            <div>Début :</div>
                                            <div>{ event.start_time}</div>
                                        </div>
                                        <div className="flex-detail-line">
                                            <div>Fin :</div>
                                            <div>{ event.end_time}</div>
                                        </div>
                                        <div className="flex-detail-line">
                                            <div>Lieu :</div>
                                            <div>{ event.location }</div>
                                        </div>
                                        {
                                            event.guests != null && event.guests.length > 0 ?
                                                <div className="flex-detail-line">
                                                    <div>Invités :</div>
                                                    <div>
                                                        {
                                                            event.guests.map(guest => {
                                                                return <div key={"guest-" + guest.id} className="mb-1">
                                                                    {
                                                                        !event.hasPaid ?
                                                                            <button className="btn btn-sm btn-secondary mr-3" onClick={() => this.removeGuest(guest.id)}>Supprimer</button>
                                                                        :
                                                                        null
                                                                    }
                                                                    {guest.name }
                                                                    {
                                                                        guest.id_event_price != null && guest.id_event_price != "" ?
                                                                            this.getPriceInfo(event, guest.id_event_price)
                                                                        :
                                                                            null
                                                                    }
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                        <div className="border-top"></div>
                                        <div className="flex-detail-line">
                                            { event.description }
                                        </div>
                                        <div className="mt-auto">
                                            <div className="flexed flexed-justify-end">
                                            {
                                                event.isRegistered ?
                                                    <div className="flexed flexed-justify-end">
                                                        {
                                                            event.start_time != null && event.start_time != "" ?
                                                                <button className="btn btn-secondary align-self-left" onClick={() => this.addToCalendar(event)}>
                                                                    <FontAwesomeIcon icon={faCalendar} />
                                                                </button>
                                                            :
                                                                null
                                                        }
                                                        {
                                                            event.prices.length > 0 && !event.hasPaid ?
                                                                <button className="btn btn-secondary" onClick={() => this.showPay(event)}>
                                                                    <FontAwesomeIcon icon={faDollarSign} /> Payer
                                                            </button>
                                                            :
                                                                null
                                                        }
                                                        {
                                                            !event.is_full && event.target_user != "Membres (sans invité)" && !event.hasPaid  ?
                                                                <button className="btn btn-success ml-3" onClick={() => this.addGuest(event, event.prices)}>
                                                                    <FontAwesomeIcon icon={faPlus} /> invité
                                                                </button>
                                                            :
                                                                null
                                                        }
                                                        <button className="btn btn-danger ml-3" onClick={() => this.setState({cancelRegisterConfirmModal : event})}>
                                                            <FontAwesomeIcon icon={faMinus} />
                                                        </button>
                                                    </div>
                                                :
                                                !event.is_full  ?
                                                    <button className="btn btn-secondary" onClick={() => this.register(event, event.prices)}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </button>
                                                :
                                                null
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        })
                    }
                </div>
    }
})))