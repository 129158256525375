import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { observer, inject  } from "mobx-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSchool, faComments, faHome, faUsers, faUserFriends } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(withRouter(observer(class Footer extends Component  {
    render() {
        let location = window.location.pathname;

        return <footer>
                    <div className={"footer-item " + (location == "/dashboard" ? "active" : "")} onClick={() => this.props.history.replace("/dashboard")}>
                        <div><FontAwesomeIcon icon={faHome} /></div>
                        <div className="item-title">Accueil</div>
                    </div>
                    {
                        this.props.data.user != null && this.props.data.user.is_workshop_speaker ?
                            <div className={"footer-item " + (location == "/workshops" ? "active" : "")} onClick={() => this.props.history.replace("/workshops")}>
                                <div><FontAwesomeIcon icon={faSchool} /></div>
                                <div className="item-title">Interventions</div>
                            </div>
                        :
                            <div className={"footer-item " + (location == "/solicitees" ? "active" : "")} onClick={() => this.props.history.replace("/solicitees")}>
                                <div><FontAwesomeIcon icon={faUserFriends} /></div>
                                <div className="item-title">Mes sollicité(e)s</div>
                            </div>
                    }

                    <div className={"footer-item " + (location == "/events" ? "active" : "")} onClick={() => this.props.history.replace("/events")}>
                        <div><FontAwesomeIcon icon={faUsers} /></div>
                        <div className="item-title">Événements</div>
                    </div>

                    <div className={"footer-item " + (location == "/chat" ? "active" : "")} onClick={() => this.props.history.replace("/chat")}>
                        <div><FontAwesomeIcon icon={faComments} /></div>
                        <div className="item-title">Clavardage</div>
                    </div>
                </footer>
    }
})))