import React, { Component } from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Dashboard from './pages/dashboard';
import Profile from './pages/profile';
import Signin from './pages/signin';
import Splash from './pages/splash';
import Events from './pages/events';
import Toolbox from './pages/toolbox';
import Solicitees from './pages/solicitees';
import Solicitee from './pages/solicitee';
import Chat from './pages/chat';
import Workshops from './pages/workshops';
import Campaigns from './pages/campaigns';
import WorkshopFilters from './pages/workshop-filters';
import TransitFees from './pages/transit-fees';
import { observer, Provider } from "mobx-react";

import 'popper.js';
import 'bootstrap';

import './scss/global.scss';
import forgotPassword from './pages/forgot-password';

export default observer(class App extends Component  {
	constructor(props) {
		super(props);

		this.stores = {
			data: this.props.data
		};
	}

	render() {
		return (
			<div className="App">
				<Provider {...this.stores}>
					<Router>
						<Switch>
							<PropsRoute path="/dashboard" component={Dashboard} />
							<PropsRoute path="/profile" component={Profile} />
							<PropsRoute path="/signin" component={Signin} />
							<PropsRoute path="/forgot-password" component={forgotPassword} />
							<PropsRoute path="/workshops" component={Workshops} />
							<PropsRoute path="/toolbox" component={Toolbox} />
							<PropsRoute path="/workshop-filters" component={WorkshopFilters} />
							<PropsRoute path="/events" component={Events} />
							<PropsRoute path="/solicitees" component={Solicitees} />
							<PropsRoute path="/solicitee/:id?" component={Solicitee} />
							<PropsRoute path="/chat" component={Chat} />
							<PropsRoute path="/transit-fees" component={TransitFees} />
							<PropsRoute path="/campaigns" component={Campaigns} />
							<PropsRoute path="/"  component={Splash} />
						</Switch>
					</Router>
				</Provider>
			</div>
		);
	}
});

const renderMergedProps = (component, ...rest) => {
	const finalProps = Object.assign({}, ...rest);
	return (
	  React.createElement(component, finalProps)
	);
}

const PropsRoute = ({ component, ...rest }) => {
	return (
		<Route {...rest} render={routeProps => {
		return renderMergedProps(component, routeProps, rest);
	}}/>);
}