import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';
import Header from '../components/header';
import Footer from '../components/footer';
import FormPage from '../utils/form-page';

import Select from '../components/forms/select';
import Input from '../components/forms/input';

export default inject("data")(observer(class Solicitee extends FormPage  {
    constructor(props) {
		super(props);

		this.state = {
			address : null,
			contact : null,
			solicitee : null,
			societyTypes : null,

			errorMessage : null,
			successMessage : null,
			id : this.props.match.params.id ?? 0,
		}
	}

	componentDidMount() {
		ApiWrapper.fetch(this, "/solicitees/" + this.state.id, "GET", {},
		response => {
			let form = {
				id : this.state.id,
				language : response.data.solicitee.language ?? "fr",
				solicitation : response.data.solicitee.solicitation ?? "Par le sollicitant",
				must_contact_by_email : response.data.solicitee.must_contact_by_email ?? "Par le sollicitant",
				email : response.data.contact.email ?? "",
				email2 : response.data.contact.email2 ?? "",
				phone1 : response.data.contact.phone1 ?? "",
				phone2 : response.data.contact.phone2 ?? "",
				phone3 : response.data.contact.phone3 ?? "",
				default_phone : response.data.contact.default_phone ?? "",
				prefix :  response.data.contact.prefix ?? "",
				first_name : response.data.contact.first_name ?? "",
				last_name : response.data.contact.last_name ?? "",
				job_title : response.data.contact.job_title ?? "",
				partner_prefix : response.data.contact.partner_prefix ?? "",
				partner_first_name : response.data.contact.partner_first_name ?? "",
				partner_last_name : response.data.contact.partner_last_name ?? "",
				partner_job_title : response.data.contact.partner_job_title ?? "",
				partner_email : response.data.contact.partner_email ?? "",
				employer : response.data.contact.employer ?? "",
				vouvoiement : response.data.contact.vouvoiement ?? "",
				type : response.data.solicitee.type ?? "Particulier",
				id_society_type : response.data.solicitee.id_society_type ?? "1",

				address : response.data.address.address,
				city : response.data.address.city,
				state : response.data.address.state,
				postal_code : response.data.address.postal_code,
				country : response.data.address.country,
				latitude : response.data.address.latitude,
				longitude : response.data.address.longitude,
			};

			this.setState({
				address : response.data.address,
				contact : response.data.contact,
				solicitee : response.data.solicitee,
				societyTypes : response.data.societyTypes,
				form : form,
			});
		})
	}

	doSave() {
		this.state.form.id = this.state.id;

		ApiWrapper.fetch(this, "/solicitees/" + this.state.id, "POST", this.state.form,
        response => {
			this.setState({
				successMessage : "Solicité.e sauvegardé.e",
				errorMessage : null,
				id : response.data.id
			});

			window.scrollTo(0, 0)
        },
		errorResponse => {
			let errMsg = "Veuillez vérifier vos données :";

			if (errorResponse.response.data.forEach != null) {
				errorResponse.response.data.forEach(r => {
					errMsg += "<br />- " + r;
				});
			}
			else {
				Object.entries(errorResponse.response.data).forEach(r => {
					errMsg += "<br />- " + r[1];
				});
			}

			this.setState({
				successMessage : null,
				errorMessage : errMsg
			});

			window.scrollTo(0, 0)
		})
	}

    handleSubmit(event) {
		event.preventDefault();

		this.updateAddressThenSave();
	}

    render() {

        return  <div>
			<Header title={
					this.state.id > 0 && this.state.form != null && this.state.form.type != null ?
						this.state.form.type == "Société" ?
							<span>
								{ this.state.form.employer }
							</span>
						:
							<span>
								{ this.state.form.last_name }, { this.state.form.first_name }
							</span>
					:
						"Solicité.e"
			 		} showBack={true}  />
			<main className="padded form">
				{
					this.state.form != null ?
						<div>
							<div className="box-section">
								{
									this.state.errorMessage != null ?
										<div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={ {__html : this.state.errorMessage} }></div>
									:
										null
								}
								{
									this.state.successMessage != null ?
										<div className="alert alert-success" role="alert">
											{this.state.successMessage}
										</div>
									:
										null
								}
							</div>
							<form className="" method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
								<h5>Informations générales</h5>

								<div className="form-row">
									<Select label="Type" name="type" value={this.state.form.type} list={["Particulier", "Société"]} valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Input label={this.state.form.type == "Société" ? "Société" : "Employeur"} col="12" value={this.state.form.employer} name="employer" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								{
									this.state.form.type == "Société" ?
										<div className="form-row">
											<Select label="Type de société" name="id_society_type" value={this.state.form.id_society_type} list={this.state.societyTypes} valueChanged={(name, value) => this.valueChanged(name, value) } />
										</div>
									:
										null
								}
								{
									this.state.form.type == "Société" ?
										<h5>Ressource 1</h5>
									:
										null
								}
								<div className="form-row">
									<Select label="Titre" name="prefix" prefixValue="--" value={this.state.form.prefix} list={[
										"Mlle", "Mme", "M", "Dr", "Dre", "Me", "Mr", "Mrs", "Ms", "Autre", "Non-spécifié"
									]} valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Input label="Prénom" value={this.state.form.first_name} name="first_name" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Nom" value={this.state.form.last_name} name="last_name" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Input label="Fonction au travail" value={this.state.form.job_title} name="job_title" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Input label="Courriel(s)" col="12" value={this.state.form.email} name="email" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input col="12" value={this.state.form.email2} name="email2" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								{
									this.state.form.type == "Société" ?
										<h5>Ressource 2</h5>
									:
										<h5>Partenaire</h5>
								}
								<div className="form-row">
									<Select label="Titre" name="partner_prefix" prefixValue="--" value={this.state.form.partner_prefix} list={[
										"Mlle", "Mme", "M", "Dr", "Dre", "Me", "Mr", "Mrs", "Ms", "Autre", "Non-spécifié"
									]} valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Input label="Prénom" value={this.state.form.partner_first_name} name="partner_first_name" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Nom" value={this.state.form.partner_last_name} name="partner_last_name" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Input label="Courriel" col="12" value={this.state.form.partner_email} name="partner_email" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<h5>Informations complémentaires</h5>
								<div className="form-row">
									<Input label="Tel. maison" value={this.state.form.phone1} name="phone1" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Tel. bureau" value={this.state.form.phone2} name="phone2" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Tel. cellulaire" value={this.state.form.phone3} name="phone3" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Input label="Numéro civique et rue" col="6" value={this.state.form.address} name="address" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Ville" col="6" value={this.state.form.city} name="city" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Province" col="4" value={this.state.form.state} name="state" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Pays" col="4" value={this.state.form.country} name="country" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Code postal" col="4" value={this.state.form.postal_code} name="postal_code" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Select label="Langue de communication" col="12" name="language" value={this.state.form.language} list={[
										{id : "fr", "name" : "Français"},
										{id : "en", "name" : "Anglais"},
									]} valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Select label="Je souhaite que cette personne soit sollicitée" col="12" name="solicitation" value={this.state.form.solicitation} list={[
										{id : "Non", "name" : "Non"},
										{id : "Par le sollicitant", "name" : "Oui, en mon nom"},
										{id : "Par le GRIS", "name" : "Oui, au nom du GRIS uniquement"},
									]} valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Input type="checkbox" col="12" label="Préfère être sollicité par courriel" value={this.state.form.must_contact_by_email} name="must_contact_by_email" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input type="checkbox" col="12" label="Vouvoiement" value={this.state.form.vouvoiement} name="vouvoiement" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>

								<div className="form-group mt-5">
									<button className="btn btn-block btn-primary">Enregistrer</button>
								</div>
							</form>
						</div>
					:
						null
				}
			</main>
            <Footer />
        </div>;
    }
}));