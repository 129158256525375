import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { observer, inject  } from "mobx-react";
import GrisModal from './gris_modal';
import Menu from './menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(withRouter(observer(class Header extends Component  {
    constructor(props) {
		super(props);

		this.state = {
			visible : false,
            notificationTitle : null,
            notificationBody : null,
		}
	}

    componentDidMount() {
        window.headerComp = this;
    }

    newNotificationMessage(title, body) {
        this.setState({
            notificationTitle : title,
            notificationBody : body
        })
    }

    removeNotification() {
        this.setState({
            notificationTitle : null,
            notificationBody : null
        })
    }

    render() {
        return <header>
                    <nav className="navbar navbar-expand-xs navbar-dark fixed-top bg-primary">
                        <div className="header-title">
                            {
                                this.props.showBack ?
                                    <div>
                                        <button className="btn btn-primary ml-2" onClick={() => this.props.history.goBack() }>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                        </button>
                                    </div>
                                :
                                    null
                            }
                            {
                                process.env.REACT_APP_ENV !== "production" ?
                                    <code>
                                        { process.env.REACT_APP_ENV }
                                    </code>
                                :
                                    null
                            }
                            <div>
                                {this.props.title}
                            </div>
                        </div>
                        <button className="navbar-toggler" type="button" onClick={() => this.setState({visible : !this.state.visible})}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </nav>
                    <div className={"navbar-collapse " + (this.state.visible ? "navbar-visible" : "")} id="navbarCollapse">
                        <Menu className="navbar-nav mr-auto" showErgonomyMenu={true} withUserInfo={true} showDashboardItem={true} showSignOut={true} />
                    </div>
                    {
                        this.state.visible ?
                            <div className="gray-overlay" onClick={() => this.setState({visible : !this.state.visible})}></div>
                        :
                            null
                    }
                    {
                        this.state.notificationTitle != null ?
                            <GrisModal title={this.state.notificationTitle} okText="Fermer" okAction={() => this.removeNotification()}>
                                {this.state.notificationBody}
                            </GrisModal>
                        :
                            null
                    }
                </header>
    }
})))