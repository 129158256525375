import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';
import Header from '../components/header';
import Footer from '../components/footer';
import FormPage from '../utils/form-page';

import Select from '../components/forms/select';
import Input from '../components/forms/input';

export default inject("data")(observer(class WorkshopFilters extends FormPage  {
    constructor(props) {
		super(props);

		this.state = {
			regions : [],
			days : [],
			form : null
		}
	}

	componentDidMount() {
		ApiWrapper.fetch(this, "/workshop-filters", "GET", {},
        response => {
			let form = response.data.filters;
			form.level_primary = form.primary
			form.level_highschool = form.secondary;
			form.level_college = form.college;
			form.lang_french = form.french;
			form.lang_english = form.english;
			form.exc_min = form.excMin;
			form.exc_max = form.excMax;

			form.ws_filter_pres_real = form.presReal;
			form.ws_filter_pres_virtual = form.presVirtual;

			this.setState({
				form : form,
				regions : response.data.regionList,
				days : response.data.dayList,
			});
        })
	}

    handleSubmit(event) {
		event.preventDefault();
		ApiWrapper.fetch(this, "/workshop-filters", "POST", this.state.form,
        response => {
			this.setState({
				successMessage : "Critères modifiés",
				errorMessage : null
			});

			window.scrollTo(0, 0)
        },
		errorResponse => {
			let errMsg = "Une erreur inattendue est survenue lors de la sauvegarde";

			this.setState({
				successMessage : null,
				errorMessage : errMsg
			});

			window.scrollTo(0, 0)
		})
	}

    render() {

        return  <div>
			<Header title="Critères d'intervention" showBack={true} />
			<main className="padded">
				{
					this.state.form != null ?
						<div>
							<div className="box-section">
								{
									this.state.errorMessage != null ?
										<div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={ {__html : this.state.errorMessage} }></div>
									:
										null
								}
								{
									this.state.successMessage != null ?
										<div className="alert alert-success" role="alert">
											{this.state.successMessage}
										</div>
									:
										null
								}
							</div>
							<form className="" method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
								<h4>
									Critères d'intervention
								</h4>

								<div>Région(s)</div>
								<div className="form-row">
									{
										this.state.form.regions.map((region, idx) => {
											return <Select key={"region" + idx} name={"region" + idx} value={region} prefixValue="- Sélectionner -" list={this.state.regions} valueChanged={(name, value) => this.valueChanged(name, value, "regions", region) } />
										})
									}
									<Select name="region-default" value={""} prefixValue="- Sélectionner -" list={this.state.regions} valueChanged={(name, value) => this.valueChanged(name, value, "regions", "") } />
								</div>

								<div>Plage(s) horaire(s)</div>
								<div className="form-row">
									{
										this.state.form.days.map((day, idx) => {
											return <Select key={"day" + idx} valueIndex="0" name={"day" + idx} value={day} prefixValue="- Sélectionner -" list={this.state.days} valueChanged={(name, value) => this.valueChanged(name, value, "days", day) } />
										})
									}
									<Select name="day-default" value={""} valueIndex="0" prefixValue="- Sélectionner -" list={this.state.days} valueChanged={(name, value) => this.valueChanged(name, value, "days", "") } />
								</div>

								<div>Mode d'intervention</div>
								<div className="form-row">
									<Input type="checkbox" col="12" label="Présentiel" value={this.state.form.ws_filter_pres_real} name="ws_filter_pres_real" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input type="checkbox" col="12" label="Virtuel" value={this.state.form.ws_filter_pres_virtual} name="ws_filter_pres_virtual" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>

								<div>Clientèle scolaire</div>
								<div className="form-row">
									<Input type="checkbox" col="12" label="Pour le secondaire" value={this.state.form.level_highschool} name="level_highschool" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input type="checkbox" col="12" label="Pour le Cégep, l'université ou clientèle adulte" value={this.state.form.level_college} name="level_college" valueChanged={(name, value) => this.valueChanged(name, value) } />
									{
										this.state.form.canGoPrimary ?
											<Input type="checkbox" col="12" label="Pour le primaire" value={this.state.form.level_primary} name="level_primary" valueChanged={(name, value) => this.valueChanged(name, value) } />
										:
											null
									}
								</div>

								<div>Langue</div>
								<div className="form-row">
									<Input type="checkbox" col="12" label="Français" value={this.state.form.lang_french} name="lang_french" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input type="checkbox" col="12" disabled={!this.state.form.canGoEnglish} label={"Anglais" + ( !this.state.form.canGoEnglish ? " (accréditation nécessaire)" : "")} value={this.state.form.lang_english} name="lang_english" valueChanged={(name, value) => {} } />
								</div>

								<div>Exclusion de date(s)</div>
								<div className="form-row">
									<Input label="Début" type="date" col="5" value={this.state.form.exc_min} name="exc_min" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<div className="col-1 text-center flexed-center">au</div>
									<Input label="Fin" type="date" col="5" value={this.state.form.exc_max} name="exc_max" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>

								<h4 className="mb-4">
									Divers
								</h4>

								<div>Transport</div>
								<div className="form-row">
									<Input type="checkbox" col="12" label="Je détiens un permis de conduire" value={this.state.form.trans_driverlic} name="trans_driverlic" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input type="checkbox" col="12" label="Je suis membre d'un service d'auto-partage" value={this.state.form.trans_communauto} name="trans_communauto" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input type="checkbox" col="12" label="Je possède une voiture " value={this.state.form.trans_hascar} name="trans_hascar" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>

								<div>Urgence</div>
								<div className="form-row">
									<Input type="checkbox" col="12" label="Je suis disponible pour les urgences" value={this.state.form.emergency} name="emergency" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>


								<div className="form-group mt-5">
									<button className="btn btn-block btn-primary">Enregistrer les modifications</button>
								</div>
							</form>
						</div>
					:
						null
				}
			</main>
            <Footer />
        </div>;
    }
}));