import React, { Component, useRef, useEffect } from 'react';
import { observer, inject  } from "mobx-react";
import { db, auth  } from "../services/firebase";
import ApiWrapper from '../utils/api-wrapper';

import Header from '../components/header';
import Footer from '../components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(observer(class ChatPage extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            loaded : false,
            errorMessage : null,
            message : "",
            messages : [],
        }

        this.user = null;
        this.lastElement = null;
    }

    componentDidMount() {
        if (this.props.data.user != null) {
            ApiWrapper.fetch(this, "/fbtoken", "GET", {},
            response => {
                auth().signInWithCustomToken(response.data.fbToken)
                .then((userCredential) => {
                    this.user = userCredential.user;

                    this.setState({
                        loaded : true,
                    }, () => {
                        let ref = db.ref(process.env.REACT_APP_ENV);

                        ref.child(this.user.uid + "").on("value", snapshot => {
                            let chats = [];

                            snapshot.forEach((snap) => {
                                let chat = snap.val();

                                if (chat.seen == null){
                                    chat.seen = [];
                                }

                                if (chat.seen.indexOf(this.user.uid) == -1) {
                                    chat.seen.push(this.user.uid);
                                    ref.child(this.user.uid + "/" + snap.ref.key).update(chat);
                                }


                                chats.push(chat);
                            });

                            this.setState({
                                messages : chats,
                                loaded : true
                            }, () => {
                                if (this.lastElement != null) {
                                    this.lastElement.scrollIntoView({ behavior: 'smooth' });
                                }
                            });
                        });
                    })
                })
                .catch((error) => {
                    this.setState({
                        errorMessage : "Impossible de se connecter au serveur."
                    })
                    console.log(error);
                });
            })
        }
    }

    componentWillUnmount() {
        auth().signOut();
    }


    handleSubmit(event) {
		event.preventDefault();

        if (this.state.message.trim().length > 0) {
            let msg = this.state.message;

            this.setState({
                errorMessage: null,
                message : "",
            }, () => {
                db.ref(process.env.REACT_APP_ENV).child(this.user.uid + "").push({
                    content: msg,
                    timestamp: Date.now(),
                    uid: this.user.uid,
                    username : this.props.data.user.username,
                    seen : [this.user.uid],
                })
                .then(res => {

                })
                .catch(err => {
                    this.setState({
                        errorMessage: "Erreur lors de l'ajout du message"
                    });
                });
            });
        }
    }

    render() {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        let foundUnread = false;
        let chatIntroMessage = "Laissez votre message, la permanence vous répondra sous peu.";

        if (this.props.data.chatIntroMessage != null && this.props.data.chatIntroMessage.length >= 10) {
            chatIntroMessage = this.props.data.chatIntroMessage;
        }

        return  <div>
			<Header title="Clavardage"/>
			<main className="padded flexed-full-height">
                    <h3 className="mb-2">Clavardage avec la permanence</h3>
                    <p className="alert alert-info mb-4" dangerouslySetInnerHTML={{__html : chatIntroMessage}}></p>

                    {
                        this.state.errorMessage != null ?
                            <div>
                                <div className="alert alert-danger" role="alert">
                                    {this.state.errorMessage}
                                </div>
                            </div>
                        :
                        null
                    }
                    {
                        this.state.loaded ?
                            <div className="chat-container">
                                <div className="chat-msgs">
                                    {this.state.messages.map(chat => {
                                        let date = new Date(chat.timestamp);
                                        let d1 = date.toLocaleDateString("fr-CA", options);
                                        let t1 = date.toLocaleTimeString("fr-CA");
                                        let dateStr =  "Par " + chat.username + ", " + d1 + " " + t1;
                                        let customClass = "";

                                        if (!foundUnread) {
                                            if (chat.seen == null || chat.seen.length == 0 || (chat.seen.length == 1 && chat.seen[0] == this.user.uid)) {
                                                foundUnread = true;
                                                customClass = "unread-line ";
                                            }
                                        }

                                        return <div key={chat.timestamp} ref={el => { this.lastElement = el; }} className={"chat-msg " + customClass + (chat.username == this.props.data.user.username ? "by-you" : "to-you")}>
                                                <div className="msg-content">{chat.content}</div>
                                                <div>{dateStr}</div>
                                            </div>
                                    })}
                                </div>
                                <div className="chat-input">
                                    <form onSubmit={(e) => {this.handleSubmit(e)}}>
								        <div className="btn-group row full-width-no-margin">
                                            <input className="form-control col-10" onChange={e => this.setState({message : e.target.value})} value={this.state.message}></input>
                                            <button className="btn btn-sm btn-primary col-2" type="submit">
                                                <FontAwesomeIcon icon={faPaperPlane} />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        :
                        null
                    }
			</main>
            <Footer />
        </div>;
    }
}));