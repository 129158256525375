import axios from 'axios';

export default class ApiWrapper {

	static async fetch(component, url, method, params, callback, errorCallback = null) {

		if (params == null) {
			params = {}
		}

		if (window.localStorage.getItem("jwt") != null) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('jwt');
		}

		return axios({
			method: method,
			url: process.env.REACT_APP_API_URL + url,
			data: params,
			params: method == "GET" ? params : null
		})
		.then(data =>  {
			if (data.data === "INTERNAL_ERROR") {
				throw new Error(data);
			}
			else if (callback != null) {
				callback(data);
			}
		})
		.catch(e => {
			if (errorCallback != null) {
				errorCallback(e);
			}
			else {
				window.localStorage.removeItem("jwt");
				component.props.history.push('/signin');

				if (e.response === undefined || (e.response.status != 401 && e.response.status != 400)) {
					console.log(e);
				}
			}
		});
	}

}