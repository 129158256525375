import React, { Component } from 'react';

export default class FormPage extends Component  {
    valueChanged(name, value, group = null, previousValue = null) {
		let form = this.state.form;

		if (group == null) {
			form[name] = value;
		}
		else {
			if (previousValue != null) {
				if (form[group] == null) {
					form[group] = [];
				}

				if (previousValue == "") {
					form[group].push(value);
				}
				else {
					if (value == "" || value == null) {
						for (let i = 0; i < form[group].length; i++) {
							if (form[group][i] == previousValue) {
								form[group].splice(i, 1);
								break;
							}
						}
					}
					else {
						for (let i = 0; i < form[group].length; i++) {
							if (form[group][i] == previousValue) {
								form[group][i] = value;
								break;
							}
						}
					}
				}
			}
		}

		this.setState({
			form : form,
			successMessage : null
		})
	}

	updateAddressThenSave() {
		if (this.state.form.postal_code == "") {
			this.state.form.latitude = "";
			this.state.form.longitude = "";
			this.doSave();
		}
		else if (this.state.form.postal_code != this.state.address.postal_code) {
			this.state.address.postal_code = this.state.form.postal_code;

			const encodedAddress = encodeURI(this.state.form.postal_code)
			fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + encodedAddress + '&key=' + process.env.REACT_APP_GOOGLE_API_KEY)
			.then(response => response.json())
			.then(response => {
				if (response.results.length == 0) {
					this.state.form.latitude = "";
					this.state.form.longitude = "";
					this.doSave();
				}
				else {
					this.state.form.latitude = response.results[0].geometry.location.lat;
					this.state.form.longitude = response.results[0].geometry.location.lng;
					this.doSave();
				}
			})
			.catch(err => {
				this.state.form.latitude = "";
				this.state.form.longitude = "";
				this.doSave();
			})
		}
		else {
			this.doSave();
		}
	}
}