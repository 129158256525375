import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';
import Header from '../components/header';
import Footer from '../components/footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faShare, faShareAlt } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(observer(class Toolbox extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            tools : []
        }
    }

    componentDidMount() {
        ApiWrapper.fetch(this, "/toolbox", "GET", {},
        response => {
			this.setState({
				tools : response.data.tools
			})
        })
    }

    share(tool) {
        if (window.shareLink != null) {
            window.shareLink.postMessage(JSON.stringify({
                name : tool.name,
                url : tool.url }));
        }
    }

    goTo(tool) {
        if (window.goTo != null && tool.url.length > 5) {
            window.goTo.postMessage(tool.url);
        }
    }

    showFiles(section, subSections = ["all"]) {
		return <section className="mt-2 padded">
                    <hr />
			        <div>
				        <h5>{ section }</h5>
                        {
                            subSections.map(subSection => {
                                return  <div key={"sub-" + subSection}>
                                            {
                                                subSection != "all" ?
                                                    <h5 className="font-weight-bold mt-2 no-border">{ subSection }</h5>
                                                :
                                                    null
                                            }
                                            {
                                                this.state.tools.map(tool => {
                                                    return tool.section_name == section &&
                                                        (subSection == "all" || tool.sub_section_name == subSection) ?
                                                            <div key={"tool-" + tool.id}>
                                                                {
                                                                    tool.url != null && tool.url.length > 0 ?
                                                                        <div>
                                                                            <div className="text-primary" onClick={() => {this.setState({ selectedId : this.state.selectedId == tool.id ? null : tool.id})}}>
                                                                            - { tool.name }
                                                                            </div>
                                                                            <div className={"ml-3 section-collapsed " + (this.state.selectedId == tool.id ? "section-expanded" : "")}>
                                                                                <div className="mt-2">
                                                                                    <button className="btn btn-sm btn-primary mr-2" onClick={() => this.share(tool)}>
                                                                                        <FontAwesomeIcon icon={faShareAlt} /> Partager
                                                                                    </button>
                                                                                    <button className="btn btn-sm btn-secondary" onClick={() => this.goTo(tool)}>
                                                                                        <FontAwesomeIcon icon={faEye} /> Visionner
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        <i>{tool.name} à venir</i>
                                                                }
                                                            </div>
                                                        :
                                                        null
                                                })
                                            }
                                        </div>
                            })
                        }
                    </div>
		        </section>
	}

    render() {
        return  <div>
                    <Header title="Boîte à outils"/>
                    {
                        this.state.tools.length > 0 ?
                            <main>
                                { this.showFiles("CALENDRIER DES FORMATIONS") }
                                { this.showFiles( "NOUVEAU DOCUMENT") }
                                { this.showFiles("DOCUMENTS D'INTERVENTION (VERSIONS FRANÇAISES)", ["Secondaire/Cégep", "Primaire"]) }
                                { this.showFiles("WORKSHOP DOCUMENTS (ENGLISH VERSIONS)", ["High School/Cegep", "Elementary School"]) }
                            </main>
                        :
                            null
                    }
                    <Footer />
                </div>
    }
}));