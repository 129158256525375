import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';

import Logo from "./../assets/img/logo.svg";

export default inject("data")(observer(class Splash extends Component  {

    componentDidMount() {
        setTimeout(() => {
            ApiWrapper.fetch(this, "/dashboard", "GET", {}, response => {
                this.props.history.replace('/dashboard');
            }, errorResponse => {
                this.props.history.replace('/signin');
            })
        }, 2500);
    }

    render() {
        return  <div className="splash">
                    <img src={Logo} alt="GRIS logo" />
                </div>;
    }
}));