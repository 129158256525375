import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';
import Header from '../components/header';
import Footer from '../components/footer';

import Select from '../components/forms/select';
import Input from '../components/forms/input';

export default inject("data")(observer(class TransitFees extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            currentFee : null,
            transitFees : null,
            choice : "",
            errorMessage : null,
            successMessage : null
        }
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        ApiWrapper.fetch(this, "/transit-fees", "GET", {},
        response => {
            let currentFee = null;

            if (response.data.fees.length > 0) {
                if (response.data.fees[0].main_status == "Envoyé") {
                    currentFee = response.data.fees[0];
                }
            }

			this.setState({
				transitFees : response.data.fees,
                currentFee : currentFee,
                choice : "",
                amount : "",
                errorMessage : null
			})
        })
    }

    valueChanged(name, value) {
        if (name == "choice") {
            this.setState({
                choice : value,
                amount : ""
            })
        }
        else if (name == "amount") {
            this.setState({
                amount : value
            })
        }
    }

    sendChoice() {
        if (this.state.currentFee != null && this.state.choice != "") {
            if (this.state.choice != 2 || (this.state.amount.trim().length > 0 && !isNaN(this.state.amount))) {
                ApiWrapper.fetch(this, "/transit-fees", "POST", {
                    id : this.state.currentFee.id,
                    choice : this.state.choice,
                    amount : this.state.amount
                },
                response => {
                    if (response.data.status == "INVALID") {
                        this.setState({
                            errorMessage : "Une erreur lors de la sauvegarde est survenue"
                        })
                    }
                    else {
                        this.setState({
                            successMessage : "Choix enregistré, merci!"
                        }, () => {
                            this.refreshData();
                        })
                    }
                })
            }
        }
    }

    render() {
        return  <div>
                    <Header title="Frais de déplacement"/>
			        <main className="padded">
                        <div className="box-section">
                            {
                                this.state.errorMessage != null ?
                                    <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={ {__html : this.state.errorMessage} }></div>
                                :
                                    null
                            }
                            {
                                this.state.successMessage != null ?
                                    <div className="alert alert-success" role="alert">
                                        {this.state.successMessage}
                                    </div>
                                :
                                    null
                            }
                        </div>
                        {
                            this.state.currentFee != null && this.state.currentFee.id != null ?
                                <div className="mb-5">
                                    <h5 className="mb-3">Que voulez-vous faire avec les frais de déplacement de l'année {this.state.currentFee.year}?</h5>

                                    <Select name="choice" col="12" value={this.state.choice} list={[
                                        { id : 0, name : "- Choisir - "},
                                        { id : 1, name : "Remboursement complet de " + this.state.currentFee.total.toFixed(2) + "$"},
                                        { id : 2, name : "Remboursement partiel"},
                                        { id : 3, name : "Demande de reçu d'impôt pour " + this.state.currentFee.total.toFixed(2) + "$"}
                                    ]} valueChanged={(name, value) => this.valueChanged(name, value) } />

                                    {
                                        this.state.choice == 2 ?
                                            <div className="mt-3 row">
                                                <div className="col-8 offset-2">
                                                    <Input col="12" placeholder="Montant à rembourser $" value={this.state.amount} name="amount" valueChanged={(name, value) => this.valueChanged(name, value) } />
                                                </div>
                                            </div>
                                        :
                                        null
                                    }

                                    {
                                        this.state.choice != "" ?
                                            <div className="text-center mt-3">
                                                <button className="btn btn-sm btn-primary" onClick={() => this.sendChoice()}>Valider mon choix</button>
                                            </div>
                                        :
                                            null
                                    }
                                    <hr />
                                </div>
                            :
                                null
                        }
                        {
                            this.state.transitFees != null ?
                                this.state.transitFees.length > 0 ?
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Année</th>
                                                <th>Montant total</th>
                                                <th>Statut</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.transitFees.map(fee => {
                                                    return <tr key={"fee-" + fee.year}>
                                                        <td>{fee.year}</td>
                                                        <td>{fee.total.toFixed(2)} $</td>
                                                        <td>{fee.main_status == "Non envoyé" ? "En cours" : fee.status}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                :
                                <div>
                                    Vous n'avez aucun frais de déplacement.
                                </div>
                            :
                                null
                        }
                    </main>
                    <Footer />
                </div>
    }
}));