import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { observer, inject  } from "mobx-react";

import ApiWrapper from '../utils/api-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMapMarkerAlt, faCalendar, faPaperclip, faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(observer(withRouter(class Workshops extends Component  {
    constructor(props) {
        super(props);

        this.state = {
            selectedId : null,
            workshops : [],
            showOrdering : this.props.showOrdering ?? false,
            ordering : "date"
        }
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.filtered != prevProps.filtered ||
            this.props.passed != prevProps.passed || this.props.assigned != prevProps.assigned) {
            this.refreshData();
        }
    }

    refreshData() {
        this.setState({
            workshops : []
        },
        () => {
            let data = {};

            if (this.props.filtered) {
                data["filtered"] = true;
            }

            if (this.props.passed) {
                data["passed"] = true;
            }

            if (this.props.assigned) {
                data["assigned"] = true;
            }

            if (this.props.limit > 0) {
                data["limit"] = this.props.limit;
            }

            ApiWrapper.fetch(this, "/workshops", "GET", data,
            response => {
                this.setOrdering(this.state.ordering, response.data.workshops);
            })
        });

    }

    setExpanded(id) {
        if (this.state.selectedId == id) {
            this.setState({
                selectedId : null
            });
        }
        else {
            this.setState({
                selectedId : id
            });
        }
    }

    changeAvailability(workshop, available) {
        ApiWrapper.fetch(this, "/update-workshop-availability", "POST", {
            id : workshop.id,
            available : available ? 1 : 0,
        },
        response => {
            if (response.data.response == "ADDED") {
                workshop.setAsAvailable = 1;
            }
            else {
                workshop.setAsAvailable = 0;
            }

            let workshops = [...this.state.workshops];

            this.setState({
                workshops : workshops
            })
        });
    }

    goTo(url) {
        if (window.goTo != null) {
            window.goTo.postMessage(url)
        }
        else {
            console.log(url)
        }
    }

    addToCalendar(workshop) {
        let d = new Date(workshop.start_date_time);
        let offset = -d.getTimezoneOffset()/60;
        let evt = {
            title : "Intervention #" + workshop.id,
            description : workshop.public_notes,
            location : workshop.name,
            start_time : workshop.start_date_time,
            end_time : workshop.end_date_time,
            timezone_offset : offset
        };

        if (window.addToCalendar != null) {
            window.addToCalendar.postMessage(JSON.stringify(evt));
        }
    }

    mailTo(workshop) {
        if (window.mailTo != null) {
            let tos = [];

            workshop.users.forEach(u => {
                tos.push(u.email);
            })

            let data = {};
            data.to = tos;
            data.cc = [];
            data.subject = "Intervention #" + workshop.id;
            data.body = "";

            window.mailTo.postMessage(JSON.stringify(data));
        }
    }

    setOrdering(ordering, forceWorkshops = null) {
        let workshops = forceWorkshops ?? [...this.state.workshops];

        workshops.sort((a, b) => {
            if (ordering == "date") {
                if (this.props.passed) {
                    return (a.start_date_time > b.start_date_time ? -1 : 1);
                }
                else {
                    return (a.start_date_time > b.start_date_time ? 1 : -1);
                }
            }
            else if (ordering == "institution") return (a.audience_info > b.audience_info ? 1 : -1);
            else if (ordering == "type") return (a.type > b.type ? 1 : -1);
        })

        this.setState({
            ordering : ordering,
            workshops : workshops
        })
    }

    render() {
        return  <div className="list">
                    {
                        this.props.assigned && !this.props.passed ?
                            <h4 className="padded">Interventions assignées</h4>
                        :
                        this.props.filtered ?
                            <h4 className="padded">Interventions ciblées à venir</h4>
                        :
                        this.props.passed ?
                            <h4 className="padded">Interventions passées</h4>
                        :
                            <h4 className="padded">Interventions</h4>
                    }
                    {
                        this.state.workshops.length == 0 ?
                            <div className="padded">
                                <i>Aucune intervention à venir...</i>
                            </div>
                        :
                        <div>
                            {
                                this.props.showOrdering ?
                                    <div className="btn-group mb-3 flexed flexed-justify-center">
                                        <button className={"btn btn-sm " + (this.state.ordering == "date" ? "btn-primary" : "btn-light border-dark")} onClick={() => this.setOrdering("date")}>
                                            Date
                                        </button>
                                        <button className={"btn btn-sm " + (this.state.ordering == "institution" ? "btn-primary" : "btn-light border-dark")} onClick={() => this.setOrdering("institution")}>
                                            Type d'établissement
                                        </button>
                                        <button className={"btn btn-sm " + (this.state.ordering == "type" ? "btn-primary" : "btn-light border-dark")} onClick={() => this.setOrdering("type")}>
                                            Type d'intervention
                                        </button>
                                    </div>
                                :
                                null
                            }
                            {
                                this.state.workshops.map(workshop => {
                                    return <div className="list-item" key={"workshop-" + workshop.id}>
                                            <div className={"item-main-line padded " + (workshop.setAsAvailable ? "available-status" : "") + (workshop.setAsAffected ? "affected-status" : "")} onClick={() => this.setExpanded(workshop.id)}>
                                                <div className="location-info">
                                                    <div>
                                                        { workshop.name }
                                                    </div>
                                                    {
                                                    workshop.setAsAvailable ?
                                                        <div>
                                                            Votre statut :
                                                            <span className="font-weight-bold text-info"> Disponible</span>
                                                        </div>
                                                    :
                                                    workshop.setAsAffected ?
                                                        <div>
                                                            Votre statut :
                                                            <span className="font-weight-bold text-success"> Affecté.e</span>
                                                        </div>
                                                    :
                                                    null
                                                }
                                                </div>
                                                <div className="general-info">
                                                    <div className="date-info">{ workshop.time_period }</div>
                                                    <div className="audience-info">{ workshop.audience_info }</div>
                                                </div>
                                            </div>
                                            <div className={"details-info padded " + (this.state.selectedId == workshop.id ? "expanded-xl" : "")}>
                                                <div className="flexed flex-justify-content">
                                                    <div className="flex-grow">
                                                        {
                                                            workshop.setAsAvailable ?
                                                                <div className="flex-detail-line">
                                                                    <div>Votre statut :</div>
                                                                    <div className="font-weight-bold">Disponible</div>
                                                                </div>
                                                            :
                                                            workshop.setAsAffected ?
                                                                <div className="flex-detail-line">
                                                                    <div>Votre statut :</div>
                                                                    <div className="font-weight-bold">Affecté.e</div>
                                                                </div>
                                                            :
                                                            null
                                                        }
                                                        <div className="flex-detail-line">
                                                            <div>Numéro :</div>
                                                            <div>{ workshop.id}</div>
                                                        </div>
                                                        <div className="flex-detail-line">
                                                            <div>Type :</div>
                                                            <div>{ workshop.type}</div>
                                                        </div>
                                                        <div className="flex-detail-line">
                                                            <div>Langue :</div>
                                                            <div>{ workshop.lang}</div>
                                                        </div>
                                                        <div className="flex-detail-line">
                                                            <div>Ville :</div>
                                                            <div>{ workshop.city}</div>
                                                        </div>
                                                        <div className="flex-detail-line">
                                                            { workshop.public_notes}
                                                        </div>
                                                    </div><div className="action-section">
                                                        {
                                                            !this.props.passed ?
                                                                <div className="">
                                                                    {
                                                                        workshop.setAsAffected && workshop.start_date_time != null && workshop.start_date_time != "" ?
                                                                            <div>
                                                                                <button className="btn btn-secondary" onClick={() => this.addToCalendar(workshop)}>
                                                                                    <FontAwesomeIcon icon={faCalendar} />
                                                                                </button>
                                                                            </div>
                                                                        :
                                                                            null
                                                                    }
                                                                    {
                                                                        workshop.setAsAffected ?
                                                                            <div>
                                                                                <button className="btn btn-primary" onClick={() => this.goTo(workshop.speaker_sheet_url)}>
                                                                                    <FontAwesomeIcon icon={faPaperclip} />
                                                                                </button>
                                                                            </div>
                                                                        :
                                                                            null
                                                                    }
                                                                    {
                                                                        workshop.setAsAffected && workshop.users != null && workshop.users.length > 1 ?
                                                                            <div>
                                                                                <button className="btn btn-primary" onClick={() => this.mailTo(workshop)}>
                                                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                                                </button>
                                                                            </div>
                                                                        :
                                                                            null
                                                                    }
                                                                        {
                                                                            !workshop.setAsAffected ?
                                                                                workshop.setAsAvailable ?
                                                                                    <div>
                                                                                        <button className="btn btn-info" onClick={() => this.changeAvailability(workshop, false)}>
                                                                                            <FontAwesomeIcon icon={faCheck} />
                                                                                        </button>
                                                                                    </div>
                                                                                :
                                                                                    <div>
                                                                                        <button className="btn btn-secondary" onClick={() => this.changeAvailability(workshop, true)}>
                                                                                            <FontAwesomeIcon icon={faCheck} />
                                                                                        </button>
                                                                                    </div>
                                                                            :
                                                                                null
                                                                        }
                                                                    <div>
                                                                    {
                                                                        workshop.latitude != null && workshop.latitude.length > 0 ?
                                                                            <div>
                                                                                <button className="btn btn-primary" onClick={() => this.goTo("https://maps.google.com/?q=" + workshop.latitude + "," + workshop.longitude)}>
                                                                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                                                </button>
                                                                            </div>
                                                                        :
                                                                            null
                                                                    }
                                                                    </div>
                                                                </div>
                                                            :
                                                                null
                                                        }
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    }
                </div>
    }
})))