import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import {Link} from "react-router-dom";

import Header from '../components/header';
import Footer from '../components/footer';
import Workshops from '../components/workshops';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(observer(class WorkshopsPage extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            passed : false,
            filtered : true,
            assigned : false,
        }
    }

    setView(passed, filtered, assigned) {
        this.setState({
            passed : passed,
            filtered : filtered,
            assigned : assigned
        })
    }

    render() {
        return  <div>
			<Header title="Interventions"/>
            <main>
                <div className="section-actions padded flexed-between mb-4" role="group">
                    <div>
                        <Link className={"btn btn-sm btn-info"} to="/workshop-filters">
                            <FontAwesomeIcon icon={faUserCog} />
                        </Link>
                    </div>
                    <div className="btn-group">
                        <button className={"btn btn-sm " + (this.state.filtered && !this.assigned ? "btn-primary" : "btn-secondary")} onClick={() => this.setView(false, true, false)}>
                            Selon mes critères
                        </button>
                        <button className={"btn btn-sm " + (!this.state.filtered && !this.state.passed && !this.state.assigned ? "btn-primary" : "btn-secondary")} onClick={() => this.setView(false, false, false)}>
                            À venir (toutes)
                        </button>
                        <button className={"btn btn-sm " + (!this.state.passed && this.state.assigned ? "btn-primary" : "btn-secondary")} onClick={() => this.setView(false, false, true)}>
                            Assignées
                        </button>
                        <button className={"btn btn-sm " + (this.state.passed ? "btn-primary" : "btn-secondary")} onClick={() => this.setView(true, false, true)}>
                            Passées
                        </button>
                    </div>
                </div>
				<Workshops passed={this.state.passed} filtered={this.state.filtered} assigned={this.state.assigned} limit={50} showOrdering={false} />
			</main>
            <Footer />
        </div>;
    }
}));