import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";

import Header from '../components/header';
import Footer from '../components/footer';
import Events from '../components/events';

export default inject("data")(observer(class EventsPage extends Component  {


    render() {
        return  <div>
			<Header title="Événements"/>
			<main>
                <Events limit={25} />
			</main>
            <Footer />
        </div>;
    }
}));