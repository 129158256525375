import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';
import {Link} from "react-router-dom";
import Header from '../components/header';
import Footer from '../components/footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(observer(class Solicitees extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            page : 0,
            count : null,
            solicitees : null,
            selectedId : null,
            show_donations_in_solicitees : false
        }
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        ApiWrapper.fetch(this, "/solicitees", "GET", {},
        response => {
			this.setState({
                count : response.data.count,
                showDonationsInSolicitees : response.data.showDonationsInSolicitees,
                years : response.data.years,
                sumDonations : response.data.sumDonations,
			}, () => {
                this.refreshSolicitees();
            })
        })
    }

    refreshSolicitees() {
        ApiWrapper.fetch(this, "/solicitees/list/" + this.state.page, "GET", {},
        response => {
			this.setState({
				solicitees : response.data.solicitees,
			})
        })
    }

    changePage(newPage) {
        this.setState({
            page : newPage
        }, () => {
            this.refreshSolicitees();
        })
    }

    setExpanded(id) {
        if (this.state.selectedId == id) {
            this.setState({
                selectedId : null
            });
        }
        else {
            this.setState({
                selectedId : id
            });
        }
    }

    render() {
        return  <div>
                    <Header title="Mes sollicité(e)s"/>
			        <main>
                        {
                            this.state.showDonationsInSolicitees && this.state.sumDonations.length > 0 ?
                                <div className="text-right">
                                    <table className="table table-striped tl-spacer text-center font-size-xs">
                                        <thead>
                                            <tr>
                                                <th colSpan="5" className="text-center">Total accumulé (dons personnels + sollicités)</th>
                                            </tr>
                                            <tr>
                                                <th>{ this.state.years[0] }</th>
                                                <th>{ this.state.years[1] }</th>
                                                <th>{ this.state.years[2] }</th>
                                                <th>{ this.state.years[3] }</th>
                                                <th>{ this.state.years[4] }</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{(parseFloat(this.state.sumDonations[0].tot ?? 0) + parseFloat(this.state.sumDonations[0].persoTot ?? 0)).toFixed(2) } $</td>
                                                <td>{(parseFloat(this.state.sumDonations[1].tot ?? 0) + parseFloat(this.state.sumDonations[1].persoTot ?? 0)).toFixed(2) } $</td>
                                                <td>{(parseFloat(this.state.sumDonations[2].tot ?? 0) + parseFloat(this.state.sumDonations[2].persoTot ?? 0)).toFixed(2) } $</td>
                                                <td>{(parseFloat(this.state.sumDonations[3].tot ?? 0) + parseFloat(this.state.sumDonations[3].persoTot ?? 0)).toFixed(2) } $</td>
                                                <td>{(parseFloat(this.state.sumDonations[4].tot ?? 0) + parseFloat(this.state.sumDonations[4].persoTot ?? 0)).toFixed(2) } $</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            :
                                null
                        }

                        <div className="padded flexed-between mt-4">
                            <h4>
                                {this.state.count ?? "Mes"} sollicité(e)s
                            </h4>
                            <div>
                                <button className="btn btn-secondary btn-sm mr-2" disabled={this.state.page <= 0} onClick={() => this.changePage(this.state.page - 1)}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <button className="btn btn-secondary btn-sm mr-4" disabled={this.state.solicitees == null || this.state.solicitees.length < 40} onClick={() => this.changePage(this.state.page + 1)}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                                <Link className={"btn btn-sm btn-info"} to={"/solicitee"}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Link>
                            </div>
                        </div>

                        {
                            this.state.solicitees != null ?
                                this.state.solicitees.length == 0 ?
                                    <div className="padded">Vous n'avez aucun sollicité.</div>
                                :
                                <div className="list">
                                    {
                                        this.state.solicitees.map(solicitee => {
                                            return <div className={"list-item " + (solicitee.solicitation == "Non" ? "faded" : "") } key={"solicitee-" + solicitee.id}>
                                                        <div className={"item-main-line padded "} onClick={() => this.setExpanded(solicitee.id)}>
                                                            <div className="location-info">
                                                                {
                                                                    solicitee.type == "Société" ?
                                                                        <span>
                                                                            { solicitee.employer }
                                                                        </span>
                                                                    :
                                                                        <span>
                                                                            { solicitee.last_name }, { solicitee.first_name }
                                                                        </span>
                                                                }
                                                            </div>
                                                            <div className="general-info">
                                                                <div>
                                                                    {
                                                                        solicitee.type == "Société" ?
                                                                            solicitee.last_name != null && solicitee.last_name.trim().length > 0 ?
                                                                                <span>
                                                                                    Ressource : { solicitee.last_name }, { solicitee.last_name }
                                                                                </span>
                                                                            :
                                                                                null
                                                                        :
                                                                            solicitee.partner_last_name != null && solicitee.partner_last_name.trim().length > 0 ?
                                                                                <span>
                                                                                    Partenaire : { solicitee.partner_last_name }, { solicitee.partner_first_name }
                                                                                </span>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                                <span>
                                                                    {solicitee.type}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className={"details-info padded " + (this.state.selectedId == solicitee.id ? "expanded" : "")}>
                                                            <div>
                                                                <div>Dons faits : </div>
                                                                {
                                                                    solicitee.donations.map((donationsPerYear, idx) => {
                                                                        return <div key={"donation-year-" + idx + "-for-" + solicitee.id} className="ml-3 flexed flex-grow-0">
                                                                                    <div className="font-weight-bold font-size-sm col-4 text-right">
                                                                                        {this.state.years[idx]} :
                                                                                    </div>
                                                                                    <div className="font-size-sm">
                                                                                    {
                                                                                        donationsPerYear.map(donation => {
                                                                                            return <div key={"donation-" + idx + "-" + donation.payment_date}>
                                                                                                    {
                                                                                                        donation.payment_date
                                                                                                    }
                                                                                                    {
                                                                                                        this.state.showDonationsInSolicitees ?
                                                                                                            <span className="ml-2">
                                                                                                                ({donation.amount} $)
                                                                                                            </span>
                                                                                                        :
                                                                                                            null
                                                                                                    }
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        donationsPerYear.length == 0 ?
                                                                                            <span>0 $</span>
                                                                                        :
                                                                                            null
                                                                                    }
                                                                                    </div>
                                                                                </div>
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="mt-auto">
                                                                <div className="flexed flexed-justify-end">
                                                                    <Link className={"btn btn-sm btn-info"} to={"/solicitee/" + solicitee.id }>
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                        })
                                    }
                                </div>
                            :
                                null
                        }
                    </main>
                    <Footer />
                </div>
    }
}));