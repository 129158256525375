import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';
import {Link} from "react-router-dom";

import Logo from "./../assets/img/logo.svg";

export default inject("data")(observer(class Signin extends Component  {
    constructor(props) {
		super(props);

		this.state = {
			username : "",
			password : "",
            errorMessage : null
		}
	}

    handleSubmit(event) {
		event.preventDefault();

		let data = {
			username : this.state.username.trim(),
			password : this.state.password,
            rId : window.rId
		};

		ApiWrapper.fetch(this, "/signin", "POST", data,
        response => {
            if (response.data != null && response.data.token != null) {
                window.localStorage.setItem("jwt", response.data.token);

			    this.props.history.replace('/dashboard');
            }
        },
        response => {
            if (response.response != null && response.response.status == 401) {
                if (response.response.data.error == "user_archived") {
                    this.setState({
                        errorMessage : "Impossible de se connecter avec cet usager, il est archivé",
                    })
                }
                else {
                    this.setState({
                        errorMessage : "Connexion erronée" + (window.rId != null && window.rId.length > 0 ? "." : ""),
                    })
                }
            }
            else {
                this.setState({
                    errorMessage : "Une erreur interne est survenue (" + response + ")"
                })
            }
        })
	}

    render() {
        return  <div className="signin">
                    <div>
                        <div className="logo-section">
                            <img src={Logo} alt="GRIS logo" />
                        </div>
                        <div className="error-section">
                            {
                                this.state.errorMessage != null ?
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.errorMessage}
                                    </div>
                                :
                                    null
                            }
                        </div>
                        <form className="" method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                            <div className="form-group">
                                <input className="form-control" placeholder="Nom d'usager" required type="text" name="username" onChange={(event) => this.setState({username : event.target.value})} />
                            </div>
                            <div className="form-group">
                                <input className="form-control" placeholder="Mot de passe" required type="password" name="password" onChange={(event) => this.setState({password : event.target.value})} />
                            </div>
                            <div className="form-group mt-5">
                                <button className="btn btn-block btn-primary">Connexion</button>
                            </div>
                        </form>
                        <div className="mt-5">
                            <Link className="text-primary" to="/forgot-password">
                                Mot de passe oublié?
                            </Link>
                        </div>
                    </div>
                </div>
    }
}));