import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { observer, inject  } from "mobx-react";
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faDoorOpen, faChalkboard, faSchool, faExclamation, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(observer(withRouter(class GrisModal extends Component  {
    constructor(props) {
		super(props);
	}

    render() {
        return <div className="modal d-block" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{this.props.title}</h4>
            </div>
            <div className="modal-body">
              {
                  this.props.children
              }
            </div>
            <div className="modal-footer">
              {
                this.props.cancelAction != null ?
                    <button type="button" className="btn btn-outline-dark" onClick={() => this.props.cancelAction() }>Annuler</button>
                :
                    null
              }
              {
                this.props.okAction != null ?
                  <button type="button" className="btn btn-primary" onClick={() => this.props.okAction() }>{this.props.okText}</button>
                :
                  null
              }
            </div>
          </div>
        </div>
      </div>
    }
})));