import React, { Component } from 'react';

export default class Input extends Component  {

    valueChanged(e) {
        if (this.props.valueChanged != null) {
            let val = e.target.value;

            if (this.props.type == "checkbox") {
                val = e.target.checked ? "1" : "";
            }

            this.props.valueChanged(this.props.name, val);
        }
    }

    render() {
        return <div className={"input-box col-" + (this.props.col ?? 6)}>
                    {
                        this.props.label != null && this.props.type != "checkbox" ?
                            <label className="form-label" htmlFor={this.props.name}>
                                {this.props.label}&nbsp;
                            </label>
                        :
                            null
                    }
                    {
                        this.props.type == "checkbox" ?
                            <div>
                                <label>
                                    <input disabled={this.props.disabled ?? false} type="checkbox" name={this.props.name} value={"1"} checked={this.props.value != null && this.props.value != ""}  onChange={e => this.valueChanged(e)} />
                                    {" " + this.props.label}
                                </label>
                            </div>
                        :
                            <input  disabled={this.props.disabled ?? false} type={this.props.type ?? "text"} placeholder={this.props.placeholder} name={this.props.name} value={this.props.value ?? ""}  onChange={e => this.valueChanged(e)} className="form-control" />
                    }
                </div>
    }
}