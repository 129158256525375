import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';
import Header from '../components/header';
import Footer from '../components/footer';
import FormPage from '../utils/form-page';

import Select from '../components/forms/select';
import Input from '../components/forms/input';

export default inject("data")(observer(class Profile extends FormPage  {
    constructor(props) {
		super(props);

		this.state = {
			user : null,
			errorMessage : null,
			successMessage : null,
		}
	}

	componentDidMount() {
		ApiWrapper.fetch(this, "/profile", "GET", {},
        response => {
			let form = {
				pronoun : response.data.contact.pronoun,
				pronoun_other : response.data.contact.pronoun_other,
				sex : response.data.user.sex,
				sex_genre : response.data.user.sex_genre,
				sex_other : response.data.user.sex_other,
				sex_genre_other : response.data.user.sex_genre_other,
				orientation : response.data.user.orientation,
				orientation_other : response.data.user.orientation_other,
				email : response.data.contact.email,
				email2 : response.data.contact.email2,
				phone1 : response.data.contact.phone1,
				phone2 : response.data.contact.phone2,
				phone3 : response.data.contact.phone3,
				address : response.data.address.address,
				city : response.data.address.city,
				state : response.data.address.state,
				postal_code : response.data.address.postal_code,
				country : response.data.address.country,
				latitude : response.data.address.latitude,
				longitude : response.data.address.longitude,
				job_type : response.data.user.job_type,
				job_details : response.data.user.job_details,
				employer : response.data.contact.employer,
				other_knowledge : response.data.user.other_knowledge,
				other_knowledge_details : response.data.user.other_knowledge_details,
				user_languages : response.data.userLanguages,
				user_regions : response.data.userRegions,
				user_countries : response.data.userCountries,
				user_identities : response.data.userIdentities,
				user_identity_other : response.data.userIdentityOther ?? "",
				password : "",
				password_confirmation : "",
			};

			this.setState({
				address : response.data.address,
				user : response.data.user,
				form : form,
				countryList : response.data.countryList,
				professionList : response.data.professionList,
				otherKnowledgeList : response.data.otherKnowledgeList,
				regionList : response.data.regionList,
				languageList : response.data.languageList,
			});
        })
	}

	doSave() {
		ApiWrapper.fetch(this, "/profile", "POST", this.state.form,
        response => {
			this.setState({
				successMessage : "Profil modifié",
				errorMessage : null
			});

			window.scrollTo(0, 0)
        },
		errorResponse => {
			let errMsg = "Veuillez vérifier vos données :";

			if (errorResponse.response.data.forEach != null) {
				errorResponse.response.data.forEach(r => {
					errMsg += "<br />- " + r;
				});
			}
			else {
				Object.entries(errorResponse.response.data).forEach(r => {
					errMsg += "<br />- " + r[1];
				});
			}

			this.setState({
				successMessage : null,
				errorMessage : errMsg
			});

			window.scrollTo(0, 0)
		})
	}

    handleSubmit(event) {
		event.preventDefault();

		this.updateAddressThenSave();
	}

    render() {
		let identities = ["Autochtone", "Asiatique", "Blanc.he", "Noir.e", "Latinoaméricain.e", "Proche-orient et Afrique du nord", "Autre"];
		let showIdentityOther = false;

		if (this.state.user != null) {
			this.state.form.user_identities.forEach(identity => {
				if (identity == "Autre") {
					showIdentityOther = true;
				}
			})
		}


        return  <div>
			<Header title="Profil" />
			<main className="padded mb-50">
				{
					this.state.user != null ?
						<div>
							<div className="box-section">
								{
									this.state.errorMessage != null ?
										<div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={ {__html : this.state.errorMessage} }></div>
									:
										null
								}
								{
									this.state.successMessage != null ?
										<div className="alert alert-success" role="alert">
											{this.state.successMessage}
										</div>
									:
										null
								}
							</div>
							<form className="" method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
								<h4 className="text-center">
									{this.props.data.user.first_name} {this.props.data.user.last_name} ({this.props.data.user.username})
								</h4>
								<h5>
									Identité et orientation
								</h5>
								<div className="form-row">
									<Select label="Orientation sexuelle" name="orientation" value={this.state.form.orientation} list={["Bisexuel.le", "Gai", "Hétérosexuel.le", "Lesbienne", "Pansexuel.le", "Autre"]} valueChanged={(name, value) => this.valueChanged(name, value) } />
									{
										this.state.form.orientation ==  "Autre" ?
											<Input label="Détails" value={this.state.form.orientation_other} name="orientation_other" valueChanged={(name, value) => this.valueChanged(name, value) } />
										:
											null
									}
								</div>
								<div className="form-row">
									<Select label="Identité de genre" name="sex" value={this.state.form.sex} list={["Homme", "Femme", "Non-binaire", "Autre"]} valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Select label="" name="sex_genre" value={this.state.form.sex_genre} list={["Cis", "Trans", "Autre"]} valueChanged={(name, value) => this.valueChanged(name, value) } />

									{
										this.state.form.sex ==  "Autre" ?
											<Input label="Détails" value={this.state.form.sex_other} name="sex_other" valueChanged={(name, value) => this.valueChanged(name, value) } />
										:
											null
									}
									{
										this.state.form.sex_genre ==  "Autre" ?
											<Input label="Détails" value={this.state.form.sex_genre_other} name="sex_genre_other" valueChanged={(name, value) => this.valueChanged(name, value) } />
										:
											null
									}
								</div>
								<div>Comment vous identifiez-vous?</div>
								<div className="form-row">
									{
										this.state.form.user_identities.map((identity, idx) => {
											return <Select key={"identity" + idx} name={"identity" + idx} value={identity} prefixValue="- Sélectionner -" list={identities} valueChanged={(name, value) => this.valueChanged(name, value, "user_identities", identity) } />
										})
									}
									<Select name="identity-default" value={""} prefixValue="- Sélectionner -" list={identities} valueChanged={(name, value) => this.valueChanged(name, value, "user_identities", "") } />
									{
										showIdentityOther ?
											<Input placeholder="Autre" value={this.state.form.user_identity_other} name="user_identity_other" valueChanged={(name, value) => this.valueChanged(name, value) } />
										:
										null
									}
								</div>
								<div>Quel pronom préférez-vous?</div>
								<div className="form-row">
									<Select name="pronoun" value={this.state.form.pronoun} prefixValue="- Sélectionner -" list={['il/lui', 'elle', 'iel', 'autre']} valueChanged={(name, value) => this.valueChanged(name, value) } />
									{
										this.state.form.pronoun == "autre" ?
											<Input placeholder="Détails" value={this.state.form.pronoun_other} name="pronoun_other" valueChanged={(name, value) => this.valueChanged(name, value) } />
										:
										null
									}
								</div>
								<h5>
									Informations de contact
								</h5>
								<div className="form-row">
									<Input label="Courriel primaire" type="email" value={this.state.form.email} name="email" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Courriel secondaire" type="email" value={this.state.form.email2} name="email2" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Input label="Tel. maison" value={this.state.form.phone1} name="phone1" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Tel. bureau" value={this.state.form.phone2} name="phone2" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Tel. cellulaire" value={this.state.form.phone3} name="phone3" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<h5>
									Adresse
								</h5>
								<div className="form-row">
									<Input label="Numéro civique et rue" col="6" value={this.state.form.address} name="address" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Ville" col="6" value={this.state.form.city} name="city" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Province" col="4" value={this.state.form.state} name="state" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Pays" col="4" value={this.state.form.country} name="country" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Code postal" col="4" value={this.state.form.postal_code} name="postal_code" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<h5>
									Autres informations
								</h5>
								<div className="form-row">
									<Select label="Profession ou domaine d'études" col="12" name="job_type" value={this.state.form.job_type} list={this.state.professionList} valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Détails" value={this.state.form.job_details} name="job_details" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Employeur" value={this.state.form.employer} name="employer" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div className="form-row">
									<Select label="Autre compétence" name="other_knowledge" value={this.state.form.other_knowledge} list={this.state.professionList} valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Détails" value={this.state.form.other_knowledge_details} name="job_details" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>
								<div>Pays où vous avez grandi</div>
								<div className="form-row">
									{
										this.state.form.user_countries.map((country, idx) => {
											return <Select key={"country" + idx} name={"country" + idx} value={country} prefixValue="- Sélectionner -" list={this.state.countryList} valueChanged={(name, value) => this.valueChanged(name, value, "user_countries", country) } />
										})
									}
									<Select name="country-default" value={""} prefixValue="- Sélectionner -" list={this.state.countryList} valueChanged={(name, value) => this.valueChanged(name, value, "user_countries", "") } />
								</div>
								<div>Langue(s) maîtrisée(s)</div>
								<div className="form-row">
									{
										this.state.form.user_languages.map((language, idx) => {
											return <Select key={"lang" + idx} name={"lang" + idx} value={language} prefixValue="- Sélectionner -" list={this.state.languageList} valueChanged={(name, value) => this.valueChanged(name, value, "user_languages", language) } />
										})
									}
									<Select name="lang-default" value={""} prefixValue="- Sélectionner -" list={this.state.languageList} valueChanged={(name, value) => this.valueChanged(name, value, "user_languages", "") } />
								</div>
								<div>Si vous avez grandi au Québec, dans quelle région?</div>
								<div className="form-row">
									{
										this.state.form.user_regions.map((region, idx) => {
											return <Select key={"region" + idx} name={"region" + idx} value={region} prefixValue="- Sélectionner -" list={this.state.regionList} valueChanged={(name, value) => this.valueChanged(name, value, "user_regions", region) } />
										})
									}
									<Select name="region-default" value={""} prefixValue="- Sélectionner -" list={this.state.regionList} valueChanged={(name, value) => this.valueChanged(name, value, "user_regions", "") } />
								</div>
								<h5>
									Modification du mot de passe
								</h5>
								<div>
									Pour modifiez votre mot de passe, veuillez le spécifier dans les cases suivantes.
								</div>
								<div className="form-row">
									<Input label="Mot de passe (8 car. min)" type="password" value={this.state.form.password} name="password" valueChanged={(name, value) => this.valueChanged(name, value) } />
									<Input label="Mot de passe (confirmation)" type="password" value={this.state.form.password_confirmation} name="password_confirmation" valueChanged={(name, value) => this.valueChanged(name, value) } />
								</div>

								<div className="form-group mt-5">
									<button className="btn btn-block btn-primary">Enregistrer les modifications</button>
								</div>
							</form>
						</div>
					:
						null
				}
			</main>

            <Footer />
        </div>;
    }
}));