import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';

import Header from '../components/header';
import Footer from '../components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faEye } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(observer(class Campaigns extends Component  {
    constructor(props) {
        super(props);

        this.state = {
            selectedId : null,
            campaigns : [],
            currentCampaign : null
        }
    }

    componentDidMount() {
        ApiWrapper.fetch(this, "/campaigns", "GET", {},
        response => {

			this.setState({
				campaigns : response.data.campaigns,
                currentCampaign : response.data.currentCampaign,
			})
        })
    }

    setExpanded(id) {
        if (this.state.selectedId == id) {
            this.setState({
                selectedId : null
            });
        }
        else {
            this.setState({
                selectedId : id
            });
        }
    }

    shareCampaign() {
        if (window.shareLink != null && this.state.currentCampaign != null) {
            window.shareLink.postMessage(JSON.stringify({
                name : this.state.currentCampaign.title,
                url : this.state.currentCampaign.url }));
        }
    }

    visitCampaign() {
        if (window.goTo != null && this.state.currentCampaign != null) {
            window.goTo.postMessage(this.state.currentCampaign.url);
        }
    }

    render() {
        let currentCampaignBar = 0;

        if (this.state.currentCampaign != null) {
            currentCampaignBar = parseInt(this.state.currentCampaign.donation_sum/this.state.currentCampaign.target_amount * 100)

            if (currentCampaignBar > 100) {
                currentCampaignBar = 100;
            }
        }

        let noPending = true;

        if (this.state.campaigns != null) {
            this.state.campaigns.forEach(campaign => {
                if (campaign.status == "En attente" || campaign.status == "Brouillon") {
                    noPending = false;
                }
            })
        }

        return  <div>
			<Header title="Mes collectes de dons"/>
			<main>
                {
                    this.state.currentCampaign != null ?
                        <div className="mb-4 padded">
                            <h4 className="text-center">Collecte en cours</h4>
                            <div className="progress">
                                <div className="progress-bar" style={{"width": currentCampaignBar + "%" }} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                { this.state.currentCampaign.donation_sum ?? 0 } $ / { this.state.currentCampaign.target_amount ?? 0 } $
                                </div>
                            </div>
                            <div className="flexed-between pt-2">
                                <button className="btn btn-sm btn-primary align-self-left" onClick={() => this.shareCampaign()}>
                                    <FontAwesomeIcon icon={faShareAlt} /> Partager
                                </button>
                                <button className="btn btn-sm btn-secondary" onClick={() => this.visitCampaign()}>
                                    <FontAwesomeIcon icon={faEye} /> Visiter
                                </button>
                            </div>
                        </div>
                    :
                    noPending ?
                        <div className="padded mb-4">
                            Vous n'avez actuellement aucune collecte en cours. Pour en créer une, veuillez utiliser votre ordinateur et aller sur l'Espace-membre du GRIS.
                        </div>
                    :
                        null
                }
                {
                    this.state.campaigns != null ?
                        this.state.campaigns.length == 0 ?
                            <div className="padded">Vous n'avez aucune campagne.</div>
                        :
                        <div className="list">
                            {
                                this.state.campaigns.map(campaign => {
                                    return <div className={"list-item "} key={"campaign-" + campaign.id}>
                                                <div className={"item-main-line padded " + (this.state.currentCampaign != null && campaign.id == this.state.currentCampaign.id ? "bg-success" : "") } onClick={() => this.setExpanded(campaign.id)}>
                                                    <div className="location-info">
                                                        { campaign.title }
                                                    </div>
                                                    <div className="general-info">
                                                        <div>
                                                            {campaign.date_start} - {campaign.date_end}
                                                        </div>
                                                        <div>
                                                            { campaign.donation_sum ?? 0 } $ / { campaign.target_amount ?? 0 } $
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"details-info details-info-lg padded " + (this.state.selectedId == campaign.id ? "expanded-lg" : "")}>
                                                    <div className="flex-detail-line">
                                                        <div>Statut :</div>
                                                        <div>{ campaign.status}</div>
                                                    </div>
                                                    <div className="flex-detail-line">
                                                        <div>Description :</div>
                                                        <div>{campaign.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                })
                            }
                        </div>
                    :
                        null
                }
			</main>
            <Footer />
        </div>;
    }
}));