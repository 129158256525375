import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';
import {withRouter} from "react-router-dom";

import Logo from "./../assets/img/logo.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronLeft, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(withRouter(observer(class ForgotPassword extends Component  {
    constructor(props) {
		super(props);

		this.state = {
			username : "",
            errorMessage : "",
            successMessage : "",
		}
	}

    handleSubmit(event) {
		event.preventDefault();

		let data = {
			username : this.state.username.trim()
		};

		ApiWrapper.fetch(this, "/forgot-password", "POST", data,
        response => {
            if (response.data.response == "INVALID_USER") {
                this.setState({
                    errorMessage : "Usager inexistant",
                    successMessage : ""
                })
            }
            else if (response.data.response == "EMAIL_SENT") {
                this.setState({
                    errorMessage : "",
                    successMessage : "Courriel envoyé"
                })
            }
        },
        response => {
            this.setState({
                errorMessage : "Une erreur interne est survenue (" + response + ")",
                successMessage : ""
            })
        })
	}

    render() {
        return  <div className="signin">
                    <div>
                        <div className="logo-section">
                            <img src={Logo} alt="GRIS logo" />
                        </div>
                        <div className="error-section">
                            {
                                this.state.errorMessage != "" ?
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.errorMessage}
                                    </div>
                                :
                                this.state.successMessage != "" ?
                                    <div className="alert alert-success" role="alert">
                                        {this.state.successMessage}
                                    </div>
                                :
                                    null
                            }
                        </div>
                        <form className="" method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                            <div className="form-group">
                                <input className="form-control" placeholder="Nom d'usager" required type="text" name="username" onChange={(event) => this.setState({username : event.target.value})} />
                            </div>
                            <div className="form-group mt-5">
                                <button className="btn btn-block btn-primary">Réinitialiser</button>
                            </div>
                        </form>
                    </div>
                    <div className="abs-bottom-right">
                        <button className="btn btn-primary" onClick={() => this.props.history.goBack() }>
                            <FontAwesomeIcon icon={faChevronLeft} /> Retour
                        </button>
                    </div>
                </div>
    }
})));