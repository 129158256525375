import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';

import Header from '../components/header';
import Footer from '../components/footer';
import Menu from '../components/menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(observer(class Dashboard extends Component  {
    constructor(props) {
		super(props);

		this.state = {
			isSpeaker : false,
			loaded : false,
            hasUnreadChatMessages : false,
		}
	}

	componentDidMount() {
		ApiWrapper.fetch(this, "/dashboard", "GET", {},
        response => {
			this.props.data.user = response.data.user;
			this.props.data.chatIntroMessage = response.data.chatIntroMessage;
			this.props.data.fontSize = parseInt(window.localStorage["fontSize"])

			document.body.style.fontSize = this.props.data.fontSize + "px";

			this.setState({
				loaded : true,
				isSpeaker : response.data.user.is_workshop_speaker,
				hasUnreadChatMessages : response.data.hasUnreadMessages,
				currentYear : new Date().getFullYear()
			})
        })
	}

    render() {
        return  <div>
			<Header title="Babillard"/>
			<main>
				{
					this.state.loaded && this.state.hasUnreadChatMessages ?
						<div className="mb-5">
							<div className="padded">
								<div className="alert alert-info mb-5" role="alert" onClick={() => this.props.history.replace("/chat")}>
									<span><FontAwesomeIcon icon={faComments} /></span> Vous avez des messages non-lus.
								</div>
							</div>
						</div>
					:
						null
				}
				<div>
					<Menu className="dashboard-menu" showDashboardItem={false} showSignOut={false} />
				</div>
			</main>
            <Footer />
        </div>
    }
}));