import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { observer, inject  } from "mobx-react";
import axios from 'axios';
import ApiWrapper from '../utils/api-wrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faDoorOpen, faChalkboard, faSchool, faUsers, faToolbox, faUserFriends, faComment, faComments, faDollarSign, faCommentDollar, faHome } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(withRouter(observer(class Menu extends Component  {

    disconnect() {
        ApiWrapper.fetch(this, "/signout", "GET", {},
        response => {
            window.localStorage.removeItem("jwt");
            axios.defaults.headers.common['Authorization'] = "";
            this.props.history.replace('/signin');
        });
    }

    setFontSize(size) {
        this.props.data.fontSize = size
        window.localStorage["fontSize"] = size
        document.body.style.fontSize = this.props.data.fontSize + "px";
    }

    render () {
        let location = window.location.pathname;

        return <div>
                    {
                        this.props.data.user != null ?
                            <ul className={this.props.className}>
                            {
                                this.props.withUserInfo ?
                                    <li className={"nav-item "}>
                                        <h4>
                                            {this.props.data.user.first_name} {this.props.data.user.last_name}
                                        </h4>
                                        @{this.props.data.user.username}
                                    </li>
                                :
                                    null
                            }
                            {
                                this.props.showErgonomyMenu ?
                                    <li className={"nav-item flexed mb-2"}>
                                            <button className={"mr-2 btn btn-ergo text-white font-size-xs " + (this.props.data.fontSize === 14 ? "bg-secondary" : "")} onClick={() => this.setFontSize(14)}>A</button>
                                            <button className={"mr-2 btn btn-ergo text-white " + (this.props.data.fontSize === 16 ? "bg-secondary" : "")} onClick={() => this.setFontSize(16)}>A</button>
                                            <button className={"mr-2 btn btn-ergo text-white font-size-xl " + (this.props.data.fontSize === 18 ? "bg-secondary" : "")} onClick={() => this.setFontSize(18)}>A</button>
                                        <hr />
                                    </li>
                                :
                                    null
                            }
                            {
                                this.props.showDashboardItem ?
                                    <li className={"nav-item " + (location == "/dashboard" ? "active" : "")}>
                                        <div className="nav-link" onClick={() => this.props.history.replace("/dashboard")}>
                                            <span><FontAwesomeIcon icon={faHome} /></span>
                                            <div>Accueil</div>
                                        </div>
                                    </li>
                                :
                                    null
                            }
                            {
                                this.props.data.user.is_workshop_speaker ?
                                    <li className={"nav-item " + (location == "/workshops" ? "active" : "")}>
                                        <div className="nav-link" onClick={() => this.props.history.replace("/workshops")}>
                                            <span><FontAwesomeIcon icon={faSchool} /></span>
                                            <div>Interventions</div>
                                        </div>
                                    </li>
                                :
                                    null
                            }
                            {
                                this.props.data.user.is_workshop_speaker ?
                                    <li className={"nav-item " + (location == "/toolbox" ? "active" : "")}>
                                        <div className="nav-link" onClick={() => this.props.history.replace("/toolbox")}>
                                            <span><FontAwesomeIcon icon={faToolbox} /></span>
                                            <div>Boîte à outils</div>
                                        </div>
                                    </li>
                                :
                                    null
                            }
                            <li className={"nav-item " + (location == "/chat" ? "active" : "")}>
                                <div className="nav-link" onClick={() => this.props.history.replace("/chat")}>
                                    <span><FontAwesomeIcon icon={faComments} /></span>
                                    <div>Clavardage</div>
                                </div>
                            </li>
                            <li className={"nav-item " + (location == "/events" ? "active" : "")}>
                                <div className="nav-link" onClick={() => this.props.history.replace("/events")}>
                                    <span><FontAwesomeIcon icon={faUsers} /></span>
                                    <div>Événements</div>
                                </div>
                            </li>
                            <li className={"nav-item " + (location == "/campaigns" ? "active" : "")}>
                                <div className="nav-link" onClick={() => this.props.history.replace("/campaigns")}>
                                    <span><FontAwesomeIcon icon={faCommentDollar} /></span>
                                    <div>Mes collectes de dons</div>
                                </div>
                            </li>
                            <li className={"nav-item " + (location == "/solicitees" ? "active" : "")}>
                                <div className="nav-link" onClick={() => this.props.history.replace("/solicitees")}>
                                    <span><FontAwesomeIcon icon={faUserFriends} /></span>
                                    <div>Mes sollicité(e)s</div>
                                </div>
                            </li>

                            {
                                this.props.data.user.is_workshop_speaker ?
                                    <li className={"nav-item " + (location == "/transit-fees" ? "active" : "")}>
                                        <div className="nav-link" onClick={() => this.props.history.replace("/transit-fees")}>
                                            <span><FontAwesomeIcon icon={faDollarSign} /></span>
                                            <div>Frais de déplacement</div>
                                        </div>
                                    </li>
                                :
                                    null
                            }
                            <li className={"nav-item " + (location == "/profile" ? "active" : "")}>
                                <div className="nav-link" onClick={() => this.props.history.replace("/profile")}>
                                    <span><FontAwesomeIcon icon={faUser} /></span>
                                    <div>Profil</div>
                                </div>
                            </li>
                            {
                                this.props.showSignOut ?
                                    <li className="nav-item">
                                        <div className="nav-link" onClick={() => this.disconnect() }>
                                            <span><FontAwesomeIcon icon={faDoorOpen} /></span>
                                            <div>Déconnexion</div>
                                        </div>
                                    </li>
                                :
                                    null
                            }
                        </ul>
                    :
                        null
                }
            </div>

    }
})))