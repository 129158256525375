import React, { Component } from 'react';

export default class Select extends Component  {

    valueChanged(e) {
        if (this.props.valueChanged != null) {
            this.props.valueChanged(this.props.name, e.target.value);
        }
    }

    render() {
        return <div className={"select-box col-" + (this.props.col ?? 6)}>
                    {
                        this.props.label != null ?
                            <label className="form-label" htmlFor={this.props.name}>
                                {this.props.label}&nbsp;
                            </label>
                        :
                            null
                    }
                    <select className="form-control" id={this.props.name} name={this.props.name} value={this.props.value} onChange={e => this.valueChanged(e)}>
                        {
                            this.props.prefixValue != null ?
                                <option value="">
                                    {this.props.prefixValue}
                                </option>
                            :
                                null
                        }
                        {
                            this.props.list.map != null ?
                                this.props.list.map(element => {
                                    let key = null;
                                    let shownValue = null;
                                    let value = null;

                                    if (typeof element === 'object' && element.id != null) {
                                        key = this.props.name + "-" + element.id;

                                        if (element.name != null) {
                                            shownValue = element.name;
                                        }
                                        else if (element.title != null) {
                                            shownValue = element.title;
                                        }
                                        else {
                                            shownValue = "N./D.";
                                        }

                                        value = shownValue;

                                        if (element.id != null) {
                                            value = element.id;
                                        }
                                    }
                                    else {
                                        key = this.props.name + "-" + element;
                                        value = element;
                                        shownValue = element;
                                    }

                                    return <option key={key} value={value}>
                                        {shownValue}
                                    </option>
                                })
                            :
                                Object.entries(this.props.list).map(element => {
                                    return <option key={this.props.name + "-" + element[0]} value={element[this.props.valueIndex ?? "1"] }>
                                        {element[1]}
                                    </option>
                                })
                        }
                    </select>
                </div>
    }
}