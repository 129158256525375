import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { observable } from "mobx";

let savedData = {
	user : null,
	chatIntroMessage : null,
	fontSize : 14,
};

const data = observable(savedData);

ReactDOM.render(
	<React.StrictMode>
    	<App data={data} />
	</React.StrictMode>,
	document.getElementById('root')
);
